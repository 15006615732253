<template>
  <div
    v-if="finance && currenFinance"
  >
    <h4 class="h4">
      Order summary
    </h4>
    <ul class="tabs no_print" v-if="Object.keys(finance).length > 1">
      <li
        v-for="(type, j) in finance"
        class="tabs__item"
        @click="changeTab(j)"
      >
        <span
          class="tabs__link"
          :class="{tabs__link_active: currenFinance === j}"
        >{{ j }}</span>
      </li>
    </ul>
    <table
      cellpadding="5"
      cellspacing="5"
      class="price"
    >
      <tr
        v-for="(i, j) in getFilteredData"
        :key="j"
      >
        <td :colspan="{'2' :  i.header}" :class="{ strong: i.bold || i.header }">
            <span v-if="i.name" >{{i.name}}</span>
            <span v-else>{{ j }}</span>
          <p
            v-if="i.note"
            class="h6_small"
          >
            *{{ i.note }}
          </p>
        </td>
        <td class="h6_right text_right" v-if="!i.header">
          <span
            v-if="i.format === 'percent'"
            :class="{ strong: i.bold }"
          >
            {{
              Number(i.amount)
                .toFixed(1)
                .replace(/\d(?=(\d{3})+\.)/g, '$&,')
            }}%
          </span>
          <span
            v-else
            :class="{ strong: i.bold }"
          >
            $
            {{
              Number(i.amount)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, '$&,')
            }}
          </span>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
    name: 'SummaryTabs',
    props: ['finance', 'printWindow'],

    data() {
        return {
            currenFinance: null,
            i : 0
        }
    },
  computed: {
    getFilteredData () {
      return Object.fromEntries(Object.entries(this.finance[this.currenFinance]).filter(([key, value]) =>
        (((value.amount && value.amount != 0) || value.header === true) && value.visible !== false)))
    }
  },
    watch: {
        finance: {
            immediate: true,
            handler(val, old) {
                if (val && Object.keys(val).length > 0 && !this.currenFinance)
                    this.changeTab(Object.keys(val)[0])
            }
        }
    },

    methods: {
        changeTab(i) {
            this.currenFinance = i
            this.$emit('change', i)
        },

    }

}
</script>

<style scoped lang="scss">
    @import '../../../src/assets/scss/utils/vars';

    .price {
        width: 100%;
    }

    .strong {
        font-weight: bold;
    }

    @media print {

        .container {
            font-size: 11px !important;
        }

        .no_print {
            display: none;
        }

    }
</style>
