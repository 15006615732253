<template>
    <div id="printOrderView" class="container">
        <section v-if="!items.dealer">
            <inline-preloader message="Loading data, please wait..." />
        </section>
        <section v-else>
            <div class="row">
                <div v-bind:class="{ 'col-4': printWindow }" class="col-md-4">
                    <h4 class="h4">
                        Order
                        {{ items.status == 'PEND' ? 'Review' : 'Details' }}
                    </h4>
                    <p class="">Order ID Number: {{ this.items.order_id }}</p>
                    <p>Date: {{ this.items.order_date }}</p>
                    <p v-if="this.items.reference">Reference: {{ this.items.reference }}</p>
                    <h4 class="h4 mt-2">Dealer</h4>
                    <p>Dealer: {{ this.items.dealer.name }}</p>
                    <!--<p>Sales Contact: {{this.items.dealer.contact}}</p>-->
                    <p>Email: {{ this.items.dealer.email }}</p>
                    <p>Phone: {{ this.items.dealer.phone }}</p>
                </div>
                <div v-bind:class="{ 'col-4': printWindow }" class="col-md-4">
                    <h4 class="h4">Customer</h4>
                    <div v-if="items.customer">
                        <p>Name: {{ items.customer.name }}</p>
                        <p>Email: {{ this.items.customer.email }}</p>
                        <p>Phone: {{ this.items.customer.phone }}</p>
                        <p>Address: {{ this.items.customer.address }}</p>
                    </div>
                    <div v-else>No customer</div>
                </div>
                <div class="col-md-4"
                        v-bind:class="{ 'col-4': printWindow }">
                    <summary-tabs :finance="items.finance" @change="changeTab" :print-window="printWindow" />
                </div>
                <div class="col-12 no_print" v-if="isAdmin()">
<!--                    <button-->
<!--                        v-if="items.status == 'PROD'"-->
<!--                        class="btn btn_default"-->
<!--                        type="button"-->
<!--                        @click.prevent="completeOrder(items.order_id)"-->
<!--                    >-->
<!--                        Complete Order-->
<!--                    </button>-->
                    <button
                        v-if="items.status == 'PEND'"
                        class="btn btn_default"
                        type="button"
                        @click.prevent="approveOrder(items.order_id)"
                    >
                        Approve
                    </button>
                    <button
                        v-if="items.status == 'PEND'"
                        class="btn btn_default"
                        type="button"
                        @click.prevent="openModalReject(items.order_id)"
                    >
                        Reject
                    </button>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-12">
                    <h4 class="h4">Product Details</h4>
                </div>
            </div>

            <div
                class="row small item-container"
                v-for="(item, i) in mainItemsRemaped"
                :key="i"
            >
                <hr class="divider mx-2" />

                <div class="col-12">
                    <h4 class="h4">{{ item.name }}</h4>
                </div>
                <div v-bind:class="{ 'col-2': printWindow }" class="col-md-1">
                    <img :src="item.icon" class="item-icon" />
                </div>
                <div v-bind:class="{ 'col-4': printWindow }" class="col-md-5">
                    <!-- <h4 class="h4"> Defaults</h4> -->
                    <div v-for="(val, key) in item.col1" :key="key">
                        {{ key }}:
                        <span v-if="key == 'Out of Square File'"
                            ><a
                                @click="openImagePreview(val)"
                                class="btn_link"
                                ><svg-icon name="file" /> View</a
                            ></span>
                        <span v-else v-html="val"></span>
                    </div>
                </div>
                <div v-bind:class="{ 'col-6': printWindow }" class="col-md-6">
                    <!-- <h4 class="h4"> Additional options</h4> -->
                    <div v-if="key !== 'Out of Square Table'" v-for="(val, key) in item.col2" :key="key">
                        {{ key }}:
                        <span v-if="key == 'Out of Square File'">
                          <a @click="openImagePreview(val)" class="btn_link">
                            <svg-icon name="file" /> View
                          </a>
                          <span class="ml-2" :class="{ 'd-none': printWindow }">
                            <input :id="`${item.id}-print-osqr`" :checked="true" type="checkbox" :value="item" v-model="outOfSquarePrints">
                            <label :for="`${item.id}-print-osqr`">Include a drawing on printout</label>
                          </span>
                        </span>
                        <span v-else v-html="val"></span>
                    </div>
                </div>
                <div
                    v-bind:class="{ 'col-6': printWindow }"
                    class="col-md-6 text_right"
                    v-if="currenFinance && item.finance"
                >
                    <p
                        class="text_print" v-if="currenFinance !== 'customer'"
                    >
                        Cost ${{
                            Number(item.finance[currenFinance]['Products Cost'].amount)
                                .toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                        }}
                    </p>
                </div>
                <div
                    v-bind:class="{ 'col-6': printWindow }"
                    class="col-md-6 text_right"
                    v-if="currenFinance && item.finance"
                >
                    <p class="text_print">
                        Price ${{
                            Number(item.finance[currenFinance]['Product Price'].amount)
                                .toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                        }}
                    </p>
                </div>
            </div>

            <div
                class="row"
                v-if="
                    this.items.accessories && this.items.accessories.length > 0
                "
                style="page-break-inside: avoid"
            >
                <div class="col-12">
                    <hr class="divider px-2" v-if="printWindow" />
                    <h4 class="h4">Additional Accessories</h4>
                </div>
                <div class="col-12">
                    <div
                        class="row"
                        v-for="(accessory, key) in this.items.accessories"
                        :key="key"
                    >
                        <div
                            v-bind:class="{ 'col-6': printWindow }"
                            class="col-md-6"
                        >
                            {{ Number(accessory.qty).toFixed(0) }} x
                            {{ accessory.name }} -
                            {{ accessory.colour_name }}
                        </div>
                        <div
                            v-bind:class="{ 'col-3': printWindow }"
                            class="col-md-3 text_right"
                        >
                            <span
                                class="text_print"
                                v-if="currenFinance && currenFinance !== 'customer'"
                                v-bind:class="{ no_print: !print_all }"
                                >Cost ${{
                                    Number(accessory.finance[currenFinance].cost)
                                        .toFixed(2)
                                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                                }}</span
                            >
                        </div>
                        <div
                            v-bind:class="{ 'col-3': printWindow }"
                            class="col-md-3 text_right"
                        >
                            <span class="text_print" v-if="currenFinance"
                                >Price ${{
                                    Number(accessory.finance[currenFinance].price)
                                        .toFixed(2)
                                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                                }}</span
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div :class="{'d-none': !printWindow}">
              <div v-for="item in outOfSquarePrints">
                <div class="page-break"></div>
                <div class="d-flex; flex-column; align-items-center; justify-content-center" style="display: flex; flex-direction: column; align-items: center; justify-content: center">
                  <h4>{{ item.name }}</h4>
                  <h5>Location: {{ item.required['Location'] }}</h5>
                  <img :src="item.required['Out of Square File']" />
                  <div v-if="item.required['Out of Square Table']">
                      <RenderOSQRTable :table-data="item.required['Out of Square Table']"/>
                  </div>
                </div>
              </div>
            </div>
        </section>

        <div
            class="row"
            id="buttons"
            v-if="items.status == 'PEND' && isAdmin()"
        >
            <div class="col">
                <button
                    class="btn btn_default"
                    type="button"
                    @click.prevent="sendToProduction()"
                >
                    Send to Production
                </button>
                <button
                    type="button"
                    class="btn btn_default"
                    @click.prevent="openModalReject()"
                >
                    REJECT
                </button>
            </div>
        </div>

        <div class="row no_print">
            <div class="col">
                <button
                    class="btn btn_default"
                    type="button"
                    @click.prevent="backToOrder()"
                >
                    Back
                </button>
                <button
                    class="btn btn_default"
                    type="button"
                    @click.prevent="printReport()"
                >
                    Print
                </button>
            </div>
        </div>

        <transition name="fade">
            <div class="modal modal_wrap" v-if="open_reject">
                <div class="modal_popup">
                    <label>
                        <label class="form__label">
                            <span class="form__labelTitle text_white"
                                >Enter Rejection Reason</span
                            >
                            <textarea
                                class="form__input"
                                name="custom reason"
                                rows="5"
                                v-model="reject_reason"
                                placeholder="Please enter rejection reason"
                            >
                            </textarea>
                            <span class="form__error">{{
                                errors.first('custom reason')
                            }}</span>
                        </label>
                    </label>
                    <div class="justify-content-between">
                        <button
                            class="btn btn_transparent"
                            @click="closeReject"
                        ><svg-icon name="solid/times" />
                          Cancel
                        </button>
                        <button
                            class="btn btn_transparent"
                            @click="rejectOrder"
                        >
                            Reject
                        </button>
                    </div>
                </div>
            </div>
        </transition>
        <transition v-if="previewImage" name="fade" mode="out-in">
            <div class="modal modal_wrap">
                <div class="modal_popup modal_full modal_ligthBg">
                    <div class="modal_content">
                        <img :src="previewImage" />
                    </div>
                    <span class="modal__close" @click="closeImagePreview">
                         <svg-icon name="times" class="text_green"/>
                    </span>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
  import RenderOSQRTable from "@/components/orders/RenderOSQRTable";
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import Reject from './Reject';
  import router from "../../router";
  import InlinePreloader from "../parts/inlinePreloader";
  import SummaryTabs from "./SummaryTabs";

export default {
    name: 'OrderProdReviewPrint',
    components: {
        RenderOSQRTable,
        SummaryTabs,
        InlinePreloader,
        Reject
    },

    data() {
        return {
            outOfSquarePrints: [],
            open_reject: false,
            reject_reason: '',
            order_num: 0,
            mainItems: [],
            cutting_length: [],
            exchangeData: '',
            currentRouteName: '',
            accessoriesListCount: '',
            editAccessoryData: '',
            editAccessory: false,
            showBulk: false,
            code: '',
            order_id: 0,
            print_all: false,
            printWindow: false,
            previewImage: null,
            currenFinance: null,
            key: null,
        }
    },

    watch: {
        items() {
            if (Object.keys(this.items).length > 0) {
                this.order_num = this.items.order_id
                this.outOfSquarePrints = this.items.mainItems.filter(i => i.required['Out of Square File'] )
            }

        }
    },

    methods: {

        openImagePreview(val){
            this.previewImage = val
        },
        closeImagePreview(){
            this.previewImage = null
        },

        backToOrder() {
            router.push(`/order-preview/${this.items.order_id}`)
        },
        changeTab(i) {
            this.currenFinance = i
        },
        printReport() {
            this.printWindow = true
            setTimeout(() => {
                window.print()
                this.printWindow = false
            }, 100)
        },

        sendToProduction() {
            this.$store.dispatch('orderDetails/sendOrderToProd', this.items.order_id);
        },

        completeOrder(id) {
            this.$store.dispatch('orderDetails/completeOrder', id)
        },

        approveOrder(id){
            this.$store.dispatch('orderDetails/sendOrderToProd', id)
        },

        openModalReject(id) {
            this.open_reject = true
        },
        closeReject() {
            this.open_reject = false
        },
        rejectOrder() {
            if (!this.reject_reason || this.reject_reason == '') {
                return
            }

            this.$store.dispatch('orderDetails/rejectOrderProd', {order_id: this.items.order_id, reason: this.reject_reason})
        },
        isAdmin(){
            return localStorage.getItem('user_role') === 'admin';

        }
    },
    closePopup() {
        this.$store.commit('approve/closePopup', false)
        this.$store.commit('reject/closePopup', false)
    },

    computed: {
      ...mapGetters({
        items: 'orderDetails/items'
      }),

        getUserName() {
            return localStorage.getItem('nameUser')
        },
        isPrint() {
            return window && window.matchMedia('print').matches
        },
        mainItemsRemaped() {
            return this.items.mainItems.map(item => {
                item.col1 = {}
                item.col2 = {}
                const all = { ...item.required, ...item.optional }
                const keys = Object.keys(all)
                keys.slice(0, keys.length / 2 + 1).forEach(key => {
                    item.col1[key] = all[key]
                })
                keys.slice(keys.length / 2 + 1).forEach(key => {
                    item.col2[key] = all[key]
                })
                return item
            })
        }
    },
    created() {
        this.order_num = this.items.order_id

        this.mainItems = this.items.mainItems

        localStorage.setItem('order_id', this.$route.params['id'])

        this.$store.commit('orderDetails/id', localStorage.getItem('order_id'));
        if (localStorage.getItem('order_id')) {
            return this.$store.dispatch('orderDetails/productionReview', localStorage.getItem('order_id'));
        }
    },
      beforeDestroy(){
        this.$store.commit('orderDetails/items', {})
      },
    mounted() {
        this.printWindow = false
    }
}
</script>

<style scoped lang="scss">
@import '../../../src/assets/scss/utils/vars';

.btn {
    margin-right: 2rem;
}

.modal_additional .btn {
    margin-top: 40px;
}

.thumbnailsTable {
    max-width: 200px;
    margin: 0 auto;
    padding: 1rem;
    cursor: pointer;
}

.orderItem__listItem {
    display: block;
}

.accessories_list__wrapper .toggleList__title {
    width: 100%;
}
hr.divider {
    margin: 0em;
}
@media screen and (max-width: $sm) {
    .thumbnailsTable {
        position: static;
        transform: none;
    }
}

.item-icon {
    max-width: 100px;
    width: auto;
    max-height: 100px;
}

.columns {
    column-count: 2;
    column-gap: 20px;
    width: 100%;
}

.item-container {
    padding: 5px 0;
}

.orderItem {
    &_open {
        .orderItem__listItems {
            max-height: none;
            overflow: hidden;
        }
    }
}

.orderItem__listItems {
    max-height: none;
    /*overflow: auto;*/
}

.accessories_count {
    margin-bottom: 20px;
}

.modal {
    &_p90 {
        padding-bottom: 90px;
    }
}

table#slip {
    width: 100%;
    font-size: 0.7em;

    td.text-large {
        font-size: 1.3em;
    }

    .border-top {
        border-top: 2px solid;
    }

    .border-left {
        border-left: 2px solid;
    }

    .border-right {
        border-right: 2px solid;
    }

    .border-bottom {
        border-bottom: 2px solid;
    }

    .high {
        height: 4em;
    }
}

table#sheet {
    width: 100%;
    font-size: 0.7em;
    border: 1px solid;

    tbody tr {
        background-color: #ffffcc;
    }

    td,
    th {
        border: 1px solid;
    }

    td {
        text-align: center;
    }
}

table#length {
    width: 100%;

    font-size: 0.8em;

    thead {
        border: 2px solid;

        th {
            border-left: 1px solid;
            vertical-align: middle;
        }

        th.gap {
            width: 50px !important;
        }
    }

    tbody {
        tr.spacer {
            border-bottom: 2px solid;
        }

        tr.total {
            padding-top: 5px;
            background-color: #ddd;
            border-top: 2px solid;
            border-bottom: 2px solid;
        }

        tr:not(.spacer) {
            td:first-child {
                border-left: 2px solid;
            }

            td:last-child {
                border-right: 2px solid;
            }
        }

        td {
            vertical-align: middle;
            text-align: center;
        }
    }

    tr:not(.total) {
        td {
            border: 1px solid;
        }
    }

    tr.spacer {
        td {
            border: none;
        }
    }

    .gray {
        background-color: #ddd;
    }
}

.strong {
    font-weight: bold;
}

.price {
    width: 100%;
}

@media print {
    @page {
        size: 21cm 29.7cm;
    }


    .container {
        font-size: 11px !important;
    }

    #buttons {
        display: none !important;
    }

    .no_print {
        display: none;
    }

    .page-break {
        clear: both;
        page-break-after: always;
    }
    .item-container {
        page-break-inside: avoid;
    }
    h4 {
        font-size: 1rem;
    }
    .text_print {
        font-size: 1rem;
        margin: 0;
        color: $navy;
    }
    hr.divider {
        margin: 0em;
    }
}
</style>
