<template>
  <div class="modal modal_wrap" @keyup.esc="closePopup" tabindex="0">
    <Form class="form form_box modal_popup" @submit="handleReject">
      <h2 class="form__title">Please enter the rejection reason .</h2>
      <span class="modal__close" @click="closePopup">
         <svg-icon name="times"/>
      </span>
      <label class="form__label">
        <span class="form__labelTitle text_white">Enter A Reason</span>
        <Field as="textarea" class="form__input" name="custom reason" rows="5"
                  v-model="reject_reason"
                  placeholder="Enter A Reason"
                  :rules="{required: true, }"
        >
        </Field>
        <ErrorMessage class="form__error"  name="custom reason" />
      </label>
      <button type="submit" class="btn btn_transparent">Reject Order</button>
    </Form>
  </div>
</template>

<script>
  export default {
    name: "Reject",
    props: {
      id: Number,

    },
    data() {
      return {
          order_id: null,
          reject_reason: ''
      }
    },

    methods: {

      handleReject() {
       /* this.$validator.validate().then(valid => {
          if (valid) {*/
            this.$store.dispatch('reject/sendReject', {userReject: this.userReject});
          /*}
        });*/
      },

        closeReject(){
        return this.$store.commit('orderpreview/closeReject', false);
      },

      getId() {
        return this.order_id = this.id;
      },

      opacity(elem){
        if(!elem){
          return 'color: #B5B5B5';
        }
      }

    },

    computed: {
    },

    created(){
      this.getId();
    }

  }
</script>

<style scoped>
  .modal_popup textarea:enabled {
    border-color: #59b692;
  }
  .modal_popup input:disabled + .form__checkbox_icon {
    opacity: .3;
  }

</style>
